import { OrgId, OrgRole, humanReadableOrgRole } from "@hex/common";
import React, { useCallback } from "react";
import styled from "styled-components";

import { HexMenu, HexMenuItem } from "../../hex-components";
import {
  BasicOrgFragmentForPickerFragment,
  WorkspacePickerFragment,
} from "../../orgs.generated";
import { useDictQueryParams } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { ArrowRightIcon, LockIcon } from "../icons/CustomIcons";

import { usePostWorkspacePickerRedirect } from "./useWorkspacePickerNav.js";

const OrgMenu = styled(HexMenu)`
  padding: 0px;
  overflow-y: auto;

  box-shadow: ${({ theme }) => theme.boxShadow.CARD_SUBTLE};
`;

export const RowItem = styled(HexMenuItem)`
  padding: 0px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  height: 88px;

  padding: 22px 16px;

  border-bottom: 1px solid ${({ theme }) => theme.borderColor.MUTED};
`;

export const RowBody = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 4px;
`;

const RowBodySubtitle = styled.div`
  display: flex;
`;

export const RowTitle = styled.div`
  display: flex;
  gap: 5px;

  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
  font-size: ${({ theme }) => theme.fontSize.HEADING_FOUR};
`;

export const OrgSubtitleDetails = styled.div`
  color: ${({ theme }) => theme.fontColor.MUTED};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const PickerDivider = styled.div`
  width: 1px;
  margin: 4px 6px;

  background-color: ${({ theme }) => theme.borderColor.DEFAULT};
`;

const OrgSelect = styled.div`
  display: flex;

  gap: 8px;
  align-items: center;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  font-size: ${({ theme }) => theme.fontSize.DEFAULT};
`;

interface OrgRowProps {
  id: OrgId;
  displayName: string;
  orgRole?: OrgRole;
  needsLogin?: boolean;
  userCount?: number;
}

export const OrgRow: React.ComponentType<OrgRowProps> = React.memo(
  function OrgRow({ displayName, id, needsLogin, orgRole, userCount }) {
    const aOrAn =
      orgRole === OrgRole.ADMIN || orgRole === OrgRole.EDITOR ? "an" : "a";
    const queryParams = useDictQueryParams();
    const redirectTo = queryParams["redirectTo"];
    const maybePlural = userCount !== 1 ? "s" : "";

    const postWorkspacePickerRedirect = usePostWorkspacePickerRedirect();

    // NOTE - any queries/mutations that we want to run based on the org
    // selected need to be re-run after we update the local storage value (done
    // in postWorkspacePickerRedirect)
    const onClick = useCallback(() => {
      void postWorkspacePickerRedirect({
        orgId: id,
        redirectTo,
      });
    }, [id, postWorkspacePickerRedirect, redirectTo]);

    return (
      <RowItem
        data-cy={CyData.WORKSPACE_PICKER_ROW}
        text={
          <Row>
            <RowBody>
              <RowTitle>
                {displayName}
                {needsLogin && (
                  <div
                    css={`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <LockIcon iconSize={14} />
                  </div>
                )}
              </RowTitle>
              <RowBodySubtitle>
                {userCount && (
                  <OrgSubtitleDetails>
                    {userCount} member{maybePlural}
                  </OrgSubtitleDetails>
                )}
                {orgRole && userCount && <PickerDivider />}
                {orgRole && (
                  <OrgSubtitleDetails>
                    You are {aOrAn} {humanReadableOrgRole(orgRole)}
                  </OrgSubtitleDetails>
                )}
              </RowBodySubtitle>
            </RowBody>
            <OrgSelect>
              <ArrowRightIcon />
            </OrgSelect>
          </Row>
        }
        onClick={onClick}
      />
    );
  },
);

interface WorkspacePickerProps {
  authedUsers?: readonly WorkspacePickerFragment[];
  unauthUsers?: readonly BasicOrgFragmentForPickerFragment[];
}

export const WorkspacePicker: React.ComponentType<WorkspacePickerProps> =
  React.memo(function WorkspacePicker({ authedUsers, unauthUsers }) {
    return (
      <OrgMenu>
        {authedUsers &&
          authedUsers.map((user: WorkspacePickerFragment) => (
            <OrgRow
              key={user.org.id}
              displayName={user.org.displayName}
              id={user.org.id}
              needsLogin={false}
              orgRole={user.orgRole}
              userCount={
                user.org.userCount > 0 ? user.org.userCount : undefined
              }
            ></OrgRow>
          ))}
        {unauthUsers &&
          unauthUsers.map((user: BasicOrgFragmentForPickerFragment) => (
            <OrgRow
              key={user.id}
              displayName={user.org.displayName}
              id={user.org.id}
              needsLogin={true}
            ></OrgRow>
          ))}
      </OrgMenu>
    );
  });
